import React from 'react'
import Navigation from './Navigation'
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Fade,
  Slide
} from '@mui/material'
import Typical from 'react-typical'
import './styles/Header.css'
import config from '../config'
const bio = [
  "I'm a software engineer interested in web development especially back-end, Cloud computing, and Data Science",
  1000,

  'Quality delivery is my all-time priority and always put extreme level attention to detail',
  1000,

  'I have all the necessary knowledge, skills, passion to learn, and experience to develop a project from the first idea to a final product',
  1000
]

const Header = () => {
  return (
    <div id="header">
      <Navigation />
      <Slide direction="down" in={true} timeout={3000}>
        <Paper
          className="section"
          sx={{
            height: '97vh',
            backgroundImage: `linear-gradient(
          to top,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0.4)
        ),
        url(${process.env.PUBLIC_URL}/assets/cover.webp) !important`,
            backgroundSize: 'cover',
            backgroundPosition: 'initial'
          }}
        >
          <Container className="hero" maxWidth="md" sx={{ height: '100%' }}>
            <Grid
              className="hero__content"
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
              sx={{ my: 5, height: '100%' }}
            >
              <Grid item>
                <Fade in={true} timeout={1500}>
                  <Typography
                    component="h1"
                    variant="h3"
                    color="primary.contrastText"
                  >
                    Hi, I&lsquo;m Ahmed Hatem.
                  </Typography>
                </Fade>
              </Grid>
              <Typical
                steps={bio}
                loop={Infinity}
                wrapper="h2"
                className="hero__content__bio"
              />
              <Slide direction="left" in={true} timeout={2000}>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ my: 2, mx: 1 }}
                    href={`mailto:${config.EMAIL}`}
                  >
                    Contact
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ my: 2 }}
                    href={config.RESUME_URL}
                    target="_blank"
                  >
                    Resume
                  </Button>
                </Grid>
              </Slide>
            </Grid>
          </Container>
        </Paper>
      </Slide>
    </div>
  )
}

export default Header
