import React from 'react'
import {
  Container,
  Typography,
  BottomNavigation,
  Grid,
  Paper
} from '@mui/material'

const Footer = () => {
  return (
    <BottomNavigation>
      <Paper sx={{ width: '100%', height: '100%', backgroundColor: '#000000' }}>
        <Container maxWidth="md" sx={{ height: '100%' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid
              item
              xs={12}
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography sx={{ color: '#ffffff' }}>
                Copyright AhmedHatem.com © 2020 - 2022
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </BottomNavigation>
  )
}

export default Footer
