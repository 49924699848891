import { createTheme } from '@mui/material/styles'

const font = "'Poppins', sans-serif"

/* const baseDark = createTheme({
  palette: {
    mode: 'dark'
  },
  typography: {
    fontFamily: font
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: font;
          font-style: normal;
          font-display: swap;
        }`
    }
  }
}) */

export const THEME = createTheme({
  palette: {
    primary: {
      main: '#C1272DFF',
      contrastText: '#fff'
    },
    secondary: {
      main: '#000000'
    }
  },

  typography: {
    fontFamily: font
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: font;
          font-style: normal;
          font-display: swap;
        }`
    }
  }
})
