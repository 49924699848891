import React, { useRef } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {
  CardActionArea,
  Container,
  Grid,
  Typography,
  Slide
} from '@mui/material'
import useInViewPort from '../hooks/useInViewPort'

const CERTIFICATES = [
  {
    title: 'Front End Web Development Professional Nanodegree Program',
    organization: 'Udacity',
    credential_id: 'VDNLPVCS',
    credential_url: 'https://confirm.udacity.com/VDNLPVCS',
    image_path: process.env.PUBLIC_URL + '/certificates/Udacity-VDNLPVCS.jpg',
    timeout: 1000
  },
  {
    title: 'IBM Applied Data Science Specialization',
    organization: 'Coursera',
    credential_id: '3XGBHZRBE6VG',
    credential_url:
      'https://www.coursera.org/account/accomplishments/specialization/certificate/3XGBHZRBE6VG',
    image_path:
      process.env.PUBLIC_URL + '/certificates/Coursera-3XGBHZRBE6VG.jpg',
    timeout: 2000
  },
  {
    title: 'Connect - Intro to Programming',
    organization: 'Udacity',
    credential_id: 'JL2A64FU',
    credential_url: 'https://confirm.udacity.com/JL2A64FU',
    image_path: process.env.PUBLIC_URL + '/certificates/Udacity-JL2A64FU.jpg',
    timeout: 3000
  },
  {
    title: 'Advanced Full-Stack Web Development Nanodegree Program',
    organization: 'Udacity',
    credential_id: 'GTUDP77E',
    credential_url: 'https://graduation.udacity.com/confirm/GTUDP77E',
    image_path:
      process.env.PUBLIC_URL +
      '/certificates/Advanced Full-Stack Web Development Nanodegree Program-1.jpg',
    timeout: 3000
  }
]

export default function Certificates() {
  const elemRef = useRef()
  const isInViewPort = useInViewPort(elemRef)

  return (
    <Container maxWidth="md" id="certificates" ref={elemRef}>
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{ my: 10 }}
      >
        <Typography component="h2" variant="h4">
          CERTIFICATES
        </Typography>
      </Grid>
      {isInViewPort && (
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ my: 5 }}
        >
          {CERTIFICATES.map((item, i) => (
            <Grid item key={i} xs={12} md={4}>
              <Slide direction="left" in={true} timeout={item.timeout}>
                <Card sx={{ minHeight: '350px' }} raised={true}>
                  <CardActionArea
                    onClick={(e) =>
                      window.open(item.credential_url, '_blank').focus()
                    }
                  >
                    <CardMedia
                      component="img"
                      image={item.image_path}
                      alt="green iguana"
                    />
                    <CardContent sx={{ height: '121.5px' }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.organization}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Slide>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  )
}
