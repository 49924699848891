import React, { useRef } from 'react'
import { Avatar, Container, Grid, Typography, Slide } from '@mui/material'
import useInViewPort from '../hooks/useInViewPort'

const SKILLS = [
  {
    name: 'Django',
    image_path: process.env.PUBLIC_URL + '/assets/django.webp',
    timeout: 500
  },
  {
    name: 'Flask',
    image_path: process.env.PUBLIC_URL + '/assets/flask.webp',
    timeout: 1000
  },
  {
    name: 'Node.js',
    image_path: process.env.PUBLIC_URL + '/assets/nodejs.webp',
    timeout: 1000
  },
  {
    name: 'React.js',
    image_path: process.env.PUBLIC_URL + '/assets/react.webp',
    timeout: 1500
  },
  {
    name: 'PostgreSQL',
    image_path: process.env.PUBLIC_URL + '/assets/postgres.webp',
    timeout: 2000
  },
  {
    name: 'MySQL',
    image_path: process.env.PUBLIC_URL + '/assets/mysql.webp',
    timeout: 2500
  },
  {
    name: 'MongoDB',
    image_path: process.env.PUBLIC_URL + '/assets/mongodb.webp',
    timeout: 3000
  },
  {
    name: 'Agile',
    timeout: 3500
  },
  {
    name: 'Scrum',
    timeout: 4000
  },
  {
    name: 'XP',
    timeout: 4500
  },
  {
    name: 'Kanban',
    timeout: 5000
  },
  {
    name: 'PyQt',
    image_path: process.env.PUBLIC_URL + '/assets/pyqt.webp',
    timeout: 5500
  },
  {
    name: 'Jasmine',
    image_path: process.env.PUBLIC_URL + '/assets/jasmine.webp',
    timeout: 6000
  },
  {
    name: 'Docker',
    image_path: process.env.PUBLIC_URL + '/assets/docker.webp',
    timeout: 6500
  },
  {
    name: 'Git',
    image_path: process.env.PUBLIC_URL + '/assets/git.webp',
    timeout: 7000
  },
  {
    name: 'Wordpress',
    image_path: process.env.PUBLIC_URL + '/assets/wordpress.webp',
    timeout: 7500
  }
]

const LANGS = [
  {
    name: 'Python',
    image_path: process.env.PUBLIC_URL + '/assets/python.webp',
    timeout: 500
  },
  {
    name: 'JavaScript',
    image_path: process.env.PUBLIC_URL + '/assets/js.webp',
    timeout: 1000
  },
  {
    name: 'TypeScript',
    image_path: process.env.PUBLIC_URL + '/assets/ts.webp',
    timeout: 1500
  },
  {
    name: 'C++',
    image_path: process.env.PUBLIC_URL + '/assets/c++.webp',
    timeout: 2000
  },
  {
    name: 'Java',
    image_path: process.env.PUBLIC_URL + '/assets/java.webp',
    timeout: 2000
  },
  {
    name: 'HTML',
    image_path: process.env.PUBLIC_URL + '/assets/html.webp',
    timeout: 2500
  },
  {
    name: 'CSS',
    image_path: process.env.PUBLIC_URL + '/assets/css.webp',
    timeout: 3000
  },
  {
    name: 'SQL',
    timeout: 3500
  }
]

export default function Skills() {
  const elemRef = useRef()
  const isInViewPort = useInViewPort(elemRef)
  return (
    <Container maxWidth="md" id="skills" ref={elemRef}>
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{ my: 10 }}
      >
        <Typography component="h2" variant="h4">
          PROGRAMMING <br />
          <center>LANGUAGES</center>
        </Typography>
      </Grid>
      {isInViewPort && (
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ my: 5 }}
        >
          {LANGS.map((item, i) => (
            <Grid item key={i} xs={4} md={3}>
              <Slide direction="left" in={true} timeout={item.timeout}>
                {item.image_path ? (
                  <Avatar
                    alt={item.name}
                    src={item.image_path}
                    sx={{
                      width: 100,
                      height: 100,
                      margin: 'auto',
                      boxShadow: '0 0 10px 0 rgba(34, 34, 34, 1)'
                    }}
                    imgProps={{ height: '50% !important' }}
                  />
                ) : (
                  <Avatar
                    alt={item.name}
                    sx={{
                      width: 100,
                      height: 100,
                      margin: 'auto',
                      boxShadow: '0 0 10px 0 rgba(34, 34, 34, 1)',
                      backgroundColor: '#C1272DFF'
                    }}
                    imgProps={{ height: '50% !important' }}
                  >
                    {item.name}
                  </Avatar>
                )}
              </Slide>
            </Grid>
          ))}
        </Grid>
      )}
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{ my: 10 }}
      >
        <Typography component="h2" variant="h4">
          Frameworks & <center>Systems</center>
        </Typography>
      </Grid>
      {isInViewPort && (
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ my: 5 }}
        >
          {SKILLS.map((item, i) => (
            <Grid item key={i} xs={4} md={3}>
              <Slide direction="left" in={true} timeout={item.timeout}>
                {item.image_path ? (
                  <Avatar
                    alt={item.name}
                    src={item.image_path}
                    sx={{
                      width: 100,
                      height: 100,
                      margin: 'auto',
                      boxShadow: '0 0 10px 0 rgba(34, 34, 34, 1)'
                    }}
                    imgProps={{ height: '50% !important' }}
                  />
                ) : (
                  <Avatar
                    alt={item.name}
                    sx={{
                      width: 100,
                      height: 100,
                      margin: 'auto',
                      boxShadow: '0 0 10px 0 rgba(34, 34, 34, 1)',
                      backgroundColor: '#C1272DFF'
                    }}
                    imgProps={{ height: '50% !important' }}
                  >
                    {item.name}
                  </Avatar>
                )}
              </Slide>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  )
}
