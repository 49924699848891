import React, { useState } from 'react'
import {
  Link,
  AppBar,
  Toolbar,
  Container,
  IconButton,
  SwipeableDrawer,
  Divider,
  MenuList,
  MenuItem,
  Grid,
  Slide
} from '@mui/material'
import './styles/Navigation.css'
import { Menu, ChevronRight } from '@mui/icons-material'
import { motion } from 'framer-motion'

const navigationLinks = [
  { name: 'Home', href: '/' },
  { name: 'Skills', href: '#skills' },
  { name: 'Projects', href: '#projects' },
  { name: 'Certificates', href: '#certificates' },
  { name: 'Contact', href: '#header' }
]

const LOGO_VARIANTS = {
  hidden: { rotate: 0 },
  visible: {
    rotate: 360,
    transition: { duration: 1.7, repeat: Infinity, repeatDelay: 3 }
  }
}

const Navigation = () => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <Slide direction="down" in={true} timeout={3000}>
        <AppBar position="fixed" color="secondary">
          <Container maxWidth="md">
            <Toolbar disableGutters>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="center"
              >
                <Grid item sx={{ ml: '10%' }}>
                  <motion.img
                    className="logo"
                    src={process.env.PUBLIC_URL + '/LOGO.webp'}
                    alt="Logo"
                    variants={LOGO_VARIANTS}
                    initial="hidden"
                    animate="visible"
                  />
                </Grid>
              </Grid>

              <IconButton onClick={() => setOpen(true)}>
                <Menu sx={{ color: '#ffffff' }} />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>
      </Slide>
      <SwipeableDrawer
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        anchor="right"
      >
        <div>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronRight />
          </IconButton>
        </div>
        <Divider />
        <MenuList>
          {navigationLinks.map((item, i) => (
            <MenuItem key={i}>
              <Link
                className="link"
                color="inherit"
                variant="button"
                underline="none"
                href={item.href}
              >
                {item.name}
              </Link>
            </MenuItem>
          ))}
        </MenuList>
      </SwipeableDrawer>
    </div>
  )
}

export default Navigation
