import React, { useRef } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {
  Container,
  Grid,
  Typography,
  CardActions,
  Button,
  Slide
} from '@mui/material'
import useInViewPort from '../hooks/useInViewPort'

const PROJECTS = [
  {
    title: 'E-commerce Website (Django, JS, Bootstrap)',
    description:
      'This is an ecommerce website built with django, Django Rest Framework, js, jQuery , bootstrap, Postgres.',
    github_url: 'https://github.com/ahmedelraei/django-ecommerce-website',
    preview_url: 'https://elraeistore.herokuapp.com/',
    image_path: process.env.PUBLIC_URL + '/assets/ecommerce.webp',
    timeout: 1000
  },
  {
    title: 'Courses Centre Management System (Python)',
    description: 'Courses Centre Management System built with PyQt5.',
    github_url:
      'https://github.com/ahmedelraei/Courses-Centre-Management-System-pyqt5',
    preview_url: '#',
    image_path: process.env.PUBLIC_URL + '/assets/courses.webp',
    timeout: 2000
  },
  {
    title: 'Sudoku With AI Solver (Python)',
    description:
      'Sudoku Game made with PyGame uses Backtracking Algorithm to solve the grid.',
    github_url: 'https://github.com/ahmedelraei/Sudoku-With-PyGame',
    preview_url: '#',
    image_path: process.env.PUBLIC_URL + '/assets/sudoku.webp',
    timeout: 3000
  },
  {
    title: 'Getit (Youtube Downloader)',
    description:
      'A Dowloader to download YouTube videos and playlists & other files from web. It is made in python. The GUI is made by PyQt5.',
    github_url: 'https://github.com/ahmedelraei/Pyqt5-Youtube-downloader',
    preview_url: '#',
    image_path: process.env.PUBLIC_URL + '/assets/getit.webp',
    timeout: 3000
  },
  {
    title: 'Tic-Tac-Toe with AI',
    description:
      'A Dowloader to download YouTube videos and playlists & other files from web. It is made in python. The GUI is made by PyQt5.',
    github_url: 'https://github.com/ahmedelraei/Python-TicTacToe-With-AI',
    preview_url: '#',
    image_path: process.env.PUBLIC_URL + '/assets/tictactoe.webp',
    timeout: 3000
  },
  {
    title: 'Store front API (Node.js)',
    description:
      'This project aims to give you a real-world scenario in which you would make storefront endpoints and store data in database.',
    github_url: 'https://github.com/ahmedelraei/Udacity-Store-front-API',
    preview_url: '#',
    image_path: process.env.PUBLIC_URL + '/assets/store.webp',
    timeout: 3000
  }
]

export default function Projects() {
  const elemRef = useRef()
  const isInViewPort = useInViewPort(elemRef)
  return (
    <Container maxWidth="md" id="projects" ref={elemRef}>
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{ my: 10 }}
      >
        <Typography component="h2" variant="h4">
          TECHNICAL PROJECTS
        </Typography>
      </Grid>
      {isInViewPort && (
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ my: 5 }}
        >
          {PROJECTS.map((item, i) => {
            return (
              <Grid item key={i} xs={12} md={4}>
                <Slide direction="right" in={true} timeout={item.timeout}>
                  <Card sx={{ minHeight: '400px' }} raised={true}>
                    <CardMedia
                      component="img"
                      image={item.image_path}
                      alt="green iguana"
                      height="200px"
                    />
                    <CardContent sx={{ height: '200px' }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        target="_blank"
                        href={item.github_url}
                      >
                        Github
                      </Button>
                      <Button size="small">Preview</Button>
                    </CardActions>
                  </Card>
                </Slide>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Container>
  )
}
