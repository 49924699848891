import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { THEME } from './constants'
import Header from './components/Header'
import Projects from './components/Projects'
import Footer from './components/Footer'
import Certificates from './components/Certificates'
import Skills from './components/Skills'

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <Header />
      <Skills />
      <Certificates />
      <Projects />
      <Footer />
    </ThemeProvider>
  )
}

export default App
